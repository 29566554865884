.teacherFilters {
  gap: 20px;
  display: grid;
  width: 65%;
  justify-content: right;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.video {
  border-radius: 10px;
  width: 55%;
  display: block;
  margin: 0 auto;
}

@media (max-width: 680px) {
  .tableTop {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    gap: 10px;
  }
  .teacherFilters {
    grid-template-columns: 1fr 1fr;
  }
  .video {
    width: 100%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
